<style>
/* draggable div css */
.draggableClass {
  left: 30%;
  top: 25%;
  bottom: 0;
  position: absolute;
  z-index: 999;
  height: auto !important;
  max-width: 634px;
  filter: drop-shadow(3px 3px 8px rgba(0.25, 0.25, 0.5, 0.45)) !important;
}
#draggable-header {
  z-index: 10;
}

@media screen and (min-width: 1023px) {
  [showOrderWindow="50"] {
    left: 30%;
    top: 25%;
  }
}

@media screen and (max-width: 500px) {
  .draggableClass {
    left: 0;
    top: 0;
    overflow: auto;
  }
}

/* draggable div css end */
</style>
<template>
  <div
    ref="draggableContainer"
    id="draggable-container"
    :class="isFixed ? 'bottom-2 right-4 absolute' : 'draggableClass'"
  >
    <div id="draggable-header" @mousedown="dragMouseDown">
      <slot name="header" id="header"></slot>
    </div>
    <slot name="main" id="main"></slot>
    <slot name="footer" id="footer"></slot>
  </div>
</template>

<script>
export default {
  name: "DraggableDiv",
  data: function () {
    return {
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
      },
    };
  },
  props: {
    isFixed: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  methods: {
    dragMouseDown: function (event) {
      event.preventDefault();
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag: function (event) {
      event.preventDefault();
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;

      var PADDING = 0;

      var viewport = {
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
      };

      var rect = this.$refs.draggableContainer.getBoundingClientRect();
      viewport.bottom = window.innerHeight - PADDING;
      viewport.left = PADDING;
      viewport.right = window.innerWidth - PADDING;
      viewport.top = PADDING;
      var newLeft =
        this.$refs.draggableContainer.offsetLeft - this.positions.movementX;
      var newTop =
        this.$refs.draggableContainer.offsetTop - this.positions.movementY;

      if (
        newLeft < viewport.left ||
        newTop < viewport.top ||
        newLeft + rect.width > viewport.right ||
        newTop + rect.height > viewport.bottom
      ) {
        // the element will hit the boundary, do nothing...
      } else {
        // set the element's new position:
        this.$refs.draggableContainer.style.top =
          this.$refs.draggableContainer.offsetTop -
          this.positions.movementY +
          "px";
        if (this.isFixed) {
          this.$refs.draggableContainer.style.left =
            "calc(100vw-100px) !important;";
        } else {
          this.$refs.draggableContainer.style.left =
            this.$refs.draggableContainer.offsetLeft -
            this.positions.movementX +
            "px";
        }

        this.$refs.draggableContainer.style.bottom = "auto";
      }
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
      this.isbottom = false;
      this.isDrag = false;
    },
  },
  mounted() {},
};
</script>
